import { DocumentData } from 'firebase/firestore';
import { FirestoreDoc, QueryConstraintInput } from './firebaseCRUD';
import { RetryOptions } from './firebaseRetryLogic';
import {
    subscriptionService,
    CollectionSubscription,
    DocumentSubscription,
    SubscriptionOptions,
    CollectionSubscriptionOptions,
    SubscriptionStatus
} from './services/SubscriptionService';

/**
 * Additional options for subscriptions with cache control
 */
export interface CacheOptions {
    useCache?: boolean;
    cacheTTL?: number;
}

/**
 * Subscribe to a Firestore document with automatic subscription management
 * and caching to IndexedDB
 * 
 * @param path Document path
 * @param onData Callback for data updates
 * @param onError Callback for errors
 * @param cacheOptions Options for caching behavior
 * @param retryOptions Options for retry behavior
 * @returns Subscription object with methods to manage the subscription
 */
export async function useDocument<T extends DocumentData>(
    path: string,
    onData?: (data: FirestoreDoc<T> | null) => void,
    onError?: (error: Error) => void,
    cacheOptions?: CacheOptions,
    retryOptions?: Partial<RetryOptions>
): Promise<DocumentSubscription<T>> {
    const { useCache = true, cacheTTL } = cacheOptions || {};

    return subscriptionService.subscribeToDocument<T>(path, {
        onData,
        onError,
        retryOptions,
        cacheResults: true,
        useCache,
        cacheTTL
    });
}

/**
 * Subscribe to a Firestore collection with automatic subscription management
 * and caching to IndexedDB
 * 
 * @param path Collection path
 * @param onData Callback for data updates
 * @param onError Callback for errors
 * @param queryConstraints Query constraints for filtering, sorting, etc.
 * @param cacheOptions Options for caching behavior
 * @param retryOptions Options for retry behavior
 * @param filterFunction Optional function to filter the data before caching and notifying callbacks
 * @returns Subscription object with methods to manage the subscription
 */
export async function useCollection<T extends DocumentData>(
    path: string,
    onData?: (data: FirestoreDoc<T>[]) => void,
    onError?: (error: Error) => void,
    queryConstraints?: QueryConstraintInput[],
    cacheOptions?: CacheOptions,
    retryOptions?: Partial<RetryOptions>,
    filterFunction?: (data: FirestoreDoc<T>[]) => FirestoreDoc<T>[]
): Promise<CollectionSubscription<T>> {
    const { useCache = true, cacheTTL } = cacheOptions || {};

    return subscriptionService.subscribeToCollection<T>(path, {
        onData,
        onError,
        queryConstraints,
        retryOptions,
        cacheResults: true,
        useCache,
        cacheTTL,
        filterFunction
    });
}

/**
 * Get list of all active subscriptions in the application
 */
export function getActiveSubscriptions() {
    return subscriptionService.getActiveSubscriptions();
}

/**
 * Clear all active subscriptions
 * Useful when logging out a user or cleaning up resources
 */
export function clearAllSubscriptions() {
    subscriptionService.clearAllSubscriptions();
}

/**
 * Clear all cached data in IndexedDB
 * Useful when logging out or when cache needs to be refreshed
 */
export async function clearAllCache(): Promise<boolean> {
    const result = await subscriptionService.clearAllCache();
    return result;
}

/**
 * Get cache statistics
 */
export async function getCacheStats() {
    return subscriptionService.getCacheStats();
}

/**
 * Factory function to create a subscription handler with custom default options
 * Useful for setting app-wide defaults for subscriptions
 */
export function createSubscriptionFactory(
    defaultOptions: Partial<SubscriptionOptions & CacheOptions> = {}
) {
    return {
        /**
         * Subscribe to a document with custom defaults
         */
        async useDocument<T extends DocumentData>(
            path: string,
            onData?: (data: FirestoreDoc<T> | null) => void,
            onError?: (error: Error) => void,
            options?: Partial<SubscriptionOptions & CacheOptions>
        ): Promise<DocumentSubscription<T>> {
            return subscriptionService.subscribeToDocument<T>(path, {
                ...defaultOptions,
                ...options,
                onData,
                onError,
            });
        },

        /**
         * Subscribe to a collection with custom defaults
         */
        async useCollection<T extends DocumentData>(
            path: string,
            onData?: (data: FirestoreDoc<T>[]) => void,
            onError?: (error: Error) => void,
            queryConstraints?: QueryConstraintInput[],
            options?: Partial<SubscriptionOptions & CacheOptions>,
            filterFunction?: (data: FirestoreDoc<T>[]) => FirestoreDoc<T>[]
        ): Promise<CollectionSubscription<T>> {
            return subscriptionService.subscribeToCollection<T>(path, {
                ...defaultOptions,
                ...options,
                onData,
                onError,
                queryConstraints,
                filterFunction,
            });
        }
    };
} 