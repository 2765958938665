import { FieldValue } from "firebase/firestore";

export interface FirebaseObject {
  id: string;
  isSoftDeleted?: boolean;
  createdAt?: string;
  lastUpdatedAt?: string;
}

export interface TimeManagement {
  startDate?: string;
  endDate?: string;
}

export interface Customer extends FirebaseObject {
  name: string;
}

export interface SupplierReference extends FirebaseObject {
  name: string;
  supplierId: string;
}

export enum UserRoleLevels {
  ADMIN = "admin",
  MANAGER = "manager",
  QUALITY_CONTROL = "quality_control",
  CUSTOMER = "customer",
  NOT_AUTHORIZED = "not_authorized",
}

export interface UsersCompany {
  companyId: string;
  isVerified: boolean;
  userRole: UserRoleLevels;
  companyName: string;
}

export interface UserInformation extends FirebaseObject {
  companies: UsersCompany[];
  previouslySelectedCompanyId?: string;
  email: string;
  displayName?: string | null;
  photoURL?: string | null;
}

export interface AcceptedValues {
  referenceValue?: number | null;
  tolerance?: {
    lower?: number | null;
    upper?: number | null;
  };
  min?: number | null;
  max?: number | null;
  unit?: string | null;
}

export interface ChecklistItem extends FirebaseObject {
  question: string;
  notes?: string | null;
  required: boolean;
  order?: number; // starting from 0
  acceptedValues?: AcceptedValues;
  referenceImageUrls?: string[] | null;
  pdfReferences?: {
    documentId: string;
    reference: PDFReference;
  }[] | null;
}

export enum AnswerStatus {
  OK = "OK",
  NOK = "NOK",
}

export interface Answer extends FirebaseObject {
  checklistItemId: string;
  question: string;
  questionNotes?: string | null;
  required: boolean;
  acceptedValues?: AcceptedValues;
  measuredValue?: number | null;
  questionReferenceImages?: string[] | null;
  status: AnswerStatus;
  comment?: string;
  answerReferenceImagesUrls?: string[] | null;
}

export interface QualityCheck extends FirebaseObject {
  projectId: string;
  serialNumber: string;
  secondarySerialNumber?: string;
  completedAt?: string;
  completedByUserId: string;
  completedByUserName: string;
  status: QualityCheckStatus;
  canBeThumbnailShown: boolean;
  passed: boolean;
  stageId: string;
  stageName: string;
  batchId: string;
  batchName: string;
  orderId: string;
  orderName: string;
}

export type QualityCheckStatus = "in-progress" | "completed";

export interface PDFReference {
  pageNumber: number;
  coordinates: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}

export interface PDFDocument {
  id: string;
  name: string;
  url: string;
  createdAt: string;
  createdBy: string;
}

export type ProductionStageStatus = "planned" | "in_progress" | "completed" | "delayed" | "cancelled";

export interface ProductionStage extends FirebaseObject {
  name: string;
  description?: string;
  projectId: string;
  order: number;
  isThumbnailAllowed: boolean;
  canHaveSerialNumbers: boolean;
  hasSecondarySerialNumber: boolean;
  pdfDocuments?: PDFDocument[];
}

export interface ProductionStagesTimeManagement extends FirebaseObject {
  orderId: string;
  projectId: string;
  timeManagement?: Record<string, TimeManagementForProdStage>; // prodStageId -> timeManagement
}

export interface TimeManagementForProdStage extends FirebaseObject, TimeManagement {
  prodStageId: string;
  prodStageName: string;
  orderId: string;
  dependencies?: {
    stageId: string;
  }[];
  status: ProductionStageStatus;
}

export interface Project extends FirebaseObject {
  name: string;
  manufacturerId: string;
  customerId: string;
  imageUrls: string[];
  ownerId: string;
  ownerEmail: string;
  allowedQualityCheckUsersPerProductionStage?: Record<
    string,
    AllowedQualityCheckUserDetails[]
  >;
  fromOldApp?: boolean;
  isHidden?: boolean; // not used rn
  isClosed?: boolean;
}

export interface OrderTimeManagement extends TimeManagement {
  deadline?: string;
}

export interface Order extends FirebaseObject {
  name: string;
  description?: string;
  projectId: string;
  numberOfUnitsInOrder: number;
  orderDate?: string;
  externalOrderId?: string;
  timeManagement?: OrderTimeManagement;
}

export interface AllowedQualityCheckUserDetails {
  userId: string;
  userEmail: string;
}

export interface ProjectThumbnail extends FirebaseObject {
  companyId: string;
  companyName: string;
  projectName: string;
  customerId: string;
  customerName: string;
  imageUrls: string[];
}

export interface UsedSerialNumber extends FirebaseObject {
  serialNumber: string;
  projectId: string;
  stageId: string;
  batchId: string;
}

export interface GlobalSerialNumberData {
  produtionStageId: string,
  productionStageName: string,
  batchId: string,
  batchName: string,
  qualityCheckId: string;
  isCheckPassed: boolean;
  completedAt: string;
}

export interface GlobalSerialNumber extends FirebaseObject {
  // id is the serial number
  projectId: string;
  checkResults: Record<string, GlobalSerialNumberData>;
}

export interface NewUserRequest extends FirebaseObject {
  userId: string;
  userEmail: string;
  requestDate: string;
  status: "pending" | "resolved" | "rejected" | "deleted_by_user";
  resolutionDate?: string;
  resolvedByUser?: string;
  assignedRole?: UserRoleLevels;
  rejectionComment?: string;
}

export interface CompanyUser extends FirebaseObject {
  userId: string;
  email: string;
  role: UserRoleLevels;
  addedBy: string;
  customerId?: string;
  lastUpdatedBy: string;
}

export type BatchStatus = "waiting" | "in_production" | "QC pending" | "completed" | "shipped";

export interface BatchTimeManagement extends TimeManagement {
  currentStageId?: string;
  status?: BatchStatus;
}

export interface Batch extends FirebaseObject {
  name: string;
  description?: string;
  notes?: string;
  projectId: string;
  orderId: string;
  timeManagement?: BatchTimeManagement;
}

export interface UserFeedback extends FirebaseObject {
  userId: string;
  userEmail: string;
  userName: string;
  userRole: UserRoleLevels;
  companyId: string;
  companyName: string;
  feedback: string;
  attachedFiles?: string[];
  status: "added" | "in_progress" | "resolved" | "rejected" | "deleted_by_user";
  resolutionDate?: string;
  resolvedBy?: string;
  resolutionComment?: string;
  to?: string;
  message?: {
    subject: string;
    html: string;
  };
}

export interface ReservedSerialNumbers {
  companyId: string;
  reservedRanges: CompanyReservedSerialNumberRange[];
}

export interface CompanyReservedSerialNumberRange {
  id: string;
  companyId: string;
  serialNumberPrefix: string;
  serialNumberPrefixIsUsed: boolean;
  addedAt: string;
  addedBy: string;
  requestedBy: string;
  rangeStart: number;
  rangeEnd: number;
}

export interface PDFSelection {
  documentId: string;
  pageNumber: number;
  coordinates: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  type: "text" | "area" | "image";
  selectedText?: string;
}

export interface CompanyInformation {
  id: string;
  name: string;
  prefix: string;
  shortNumericId: string;
  totalPaperQualityCheckBarcodes?: number;
  qrCodeEmbeddings: string[];
}

export enum HistoryLogChangeType {
  UPDATE = "update",
  SET = "set",
  DELETE = "delete",
}

export interface HistoryLog<T> {
  changedAtLocal: string;
  changedAtServerTimestamp: FieldValue;
  changedBy: string;
  changeType: HistoryLogChangeType;
  changes: Partial<Record<keyof T, { oldValue: any; newValue: any }>>;
}

export type TaskStatus = "pending" | "in_progress" | "completed" | "cancelled";
export type TaskPriority = "low" | "medium" | "high";

export interface TaskAttachment {
  id: string;
  url?: string;
  type?: string;
  name?: string;
  isForDescription?: boolean;
}

export enum TaskMentionType {
  PROJECT = 'project',
  BATCH = 'batch',
  PRODUCTION_STAGE = 'productionStage',
  ORDER = 'order',
  USER = 'user',
}

export interface TaskMention {
  id: string;
  mentionedId: string;
  label: string;
  type: TaskMentionType;
  projectName?: string;
  projectId?: string;
}

export interface Task extends FirebaseObject {
  title: string;
  description?: string;
  status: TaskStatus;
  priority: TaskPriority;
  createdBy: string;
  dueAt?: string;
  completedAt?: string;
  completedBy?: string;
  relatedTo?: TaskRelation;
  // ownership
  ownerId: string;
  assignedTo?: string[];
  attachments?: TaskAttachment[];
  mentions?: TaskMention[];
}

export interface TaskRelation {
  id: string; // relatedTo.id (projectId, orderId, batchId)
  type: "project" | "order" | "batch";
  projectId?: string; // if type is order or batch
  orderId?: string; // if type is batch
}

export interface TaskComment extends FirebaseObject {
  taskId: string;
  comment: string;
  createdBy: string;
  createdAt: string;
}

export interface PaperQualityCheck extends FirebaseObject {
  projectId: string;
  productionStageId: string;
  orderId: string;
  batchId: string;
  version: string;
  numberOfQualityChecks: number;
  numericBarcodeId: string;
  checklistItemIdsMap: Record<string, string>; // checklistItemId -> number
}
