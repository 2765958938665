import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { FirestoreDoc } from '../firebaseCRUD';
import { DocumentData } from 'firebase/firestore';

/**
 * Schema for the IndexedDB database
 */
interface FirebaseCacheDB extends DBSchema {
    cacheEntries: {
        key: string;
        value: CacheEntry<any>;
        indexes: {
            'by-expiration': number;
            'by-path': string;
            'by-type': string;
        };
    };
}

/**
 * Represents a single cached entry in the database
 */
export interface CacheEntry<T> {
    path: string;
    queryKey?: string;
    data: T;
    timestamp: number;
    expiresAt: number;
    type: 'document' | 'collection';
}

/**
 * Cache configuration options
 */
export interface CacheConfig {
    dbName: string;
    dbVersion: number;
    defaultTTL: number; // Time to live in milliseconds
    maxEntries?: number; // Maximum number of cache entries
    autoCleanupInterval?: number; // Auto cleanup interval in milliseconds
}

/**
 * Default cache configuration
 */
const DEFAULT_CACHE_CONFIG: CacheConfig = {
    dbName: 'firebase-cache',
    dbVersion: 1,
    defaultTTL: 24 * 60 * 60 * 1000, // 24 hours
    maxEntries: 1000,
    autoCleanupInterval: 60 * 60 * 1000, // 1 hour
};

/**
 * Service for caching Firebase data in IndexedDB
 */
class CacheService {
    private static instance: CacheService;
    private db: IDBPDatabase<FirebaseCacheDB> | null = null;
    private config: CacheConfig;
    private cleanupTimer: NodeJS.Timeout | null = null;
    private dbInitPromise: Promise<IDBPDatabase<FirebaseCacheDB>> | null = null;

    private constructor(config: Partial<CacheConfig> = {}) {
        this.config = { ...DEFAULT_CACHE_CONFIG, ...config };

        // Initialize database connection
        this.initDb();

        // Schedule auto cleanup
        if (this.config.autoCleanupInterval) {
            this.startAutoCleanup();
        }
    }

    /**
     * Get the singleton instance
     */
    public static getInstance(config?: Partial<CacheConfig>): CacheService {
        if (!CacheService.instance) {
            CacheService.instance = new CacheService(config);
        }
        return CacheService.instance;
    }

    /**
     * Initialize the database connection
     */
    private async initDb(): Promise<IDBPDatabase<FirebaseCacheDB>> {
        if (this.db) return this.db;

        if (this.dbInitPromise) return this.dbInitPromise;

        this.dbInitPromise = openDB<FirebaseCacheDB>(this.config.dbName, this.config.dbVersion, {
            upgrade: (db) => {
                // Create store with indexes
                const store = db.createObjectStore('cacheEntries', { keyPath: 'path' });

                // Create indexes for faster queries
                store.createIndex('by-expiration', 'expiresAt');
                store.createIndex('by-path', 'path');
                store.createIndex('by-type', 'type');
            },
        });

        try {
            this.db = await this.dbInitPromise;
            console.log('[CacheService] IndexedDB initialized successfully');
            return this.db;
        } catch (error) {
            console.error('[CacheService] Failed to initialize IndexedDB:', error);
            this.dbInitPromise = null;
            throw error;
        }
    }

    /**
     * Generate a cache key from a path and optional query constraints
     */
    public getCacheKey(path: string, type: 'document' | 'collection', queryKey?: string): string {
        return queryKey ? `${path}:${type}:${queryKey}` : `${path}:${type}`;
    }

    /**
     * Store data in the cache
     */
    public async set<T>(
        path: string,
        data: T,
        type: 'document' | 'collection',
        queryKey?: string,
        ttl = this.config.defaultTTL
    ): Promise<void> {
        try {
            const db = await this.initDb();
            const timestamp = Date.now();
            const expiresAt = timestamp + ttl;
            const cacheKey = this.getCacheKey(path, type, queryKey);

            const entry: CacheEntry<T> = {
                path: cacheKey,
                queryKey,
                data,
                timestamp,
                expiresAt,
                type
            };

            await db.put('cacheEntries', entry);
            console.log(`[CacheService] Cached data for ${cacheKey}`);
        } catch (error) {
            console.error('[CacheService] Error caching data:', error);
            // Don't throw error - cache failures shouldn't break the app
        }
    }

    /**
     * Get data from the cache
     */
    public async get<T>(path: string, type: 'document' | 'collection', queryKey?: string): Promise<T | null> {
        try {
            const db = await this.initDb();
            const cacheKey = this.getCacheKey(path, type, queryKey);

            const entry = await db.get('cacheEntries', cacheKey);

            if (!entry) {
                console.log(`[CacheService] Cache miss for ${cacheKey}`);
                return null;
            }

            // Check if entry is expired
            if (entry.expiresAt < Date.now()) {
                console.log(`[CacheService] Expired cache entry for ${cacheKey}`);
                await this.delete(path, type, queryKey);
                return null;
            }

            console.log(`[CacheService] Cache hit for ${cacheKey}`);
            return entry.data as T;
        } catch (error) {
            console.error('[CacheService] Error retrieving cached data:', error);
            return null;
        }
    }

    /**
     * Delete data from the cache
     */
    public async delete(path: string, type: 'document' | 'collection', queryKey?: string): Promise<void> {
        try {
            const db = await this.initDb();
            const cacheKey = this.getCacheKey(path, type, queryKey);

            await db.delete('cacheEntries', cacheKey);
            console.log(`[CacheService] Deleted cache entry for ${cacheKey}`);
        } catch (error) {
            console.error('[CacheService] Error deleting cache entry:', error);
        }
    }

    /**
     * Clear all cached data
     */
    public async clearAll(): Promise<boolean> {
        try {
            console.log('[CacheService] Clearing all cache entries');
            const db = await this.initDb();
            await db.clear('cacheEntries');
            console.log('[CacheService] Cleared all cache entries');
            return true;
        } catch (error) {
            console.error('[CacheService] Error clearing cache:', error);
            return false;
        }
    }

    /**
     * Clean up expired entries
     */
    public async cleanupExpired(): Promise<number> {
        try {
            const db = await this.initDb();
            const currentTime = Date.now();

            // Get all expired entries
            const expiredEntries = await db.getAllFromIndex(
                'cacheEntries',
                'by-expiration',
                IDBKeyRange.upperBound(currentTime)
            );

            // Delete each expired entry
            for (const entry of expiredEntries) {
                await db.delete('cacheEntries', entry.path);
            }

            console.log(`[CacheService] Cleaned up ${expiredEntries.length} expired cache entries`);
            return expiredEntries.length;
        } catch (error) {
            console.error('[CacheService] Error cleaning up expired entries:', error);
            return 0;
        }
    }

    /**
     * Start automatic cleanup timer
     */
    private startAutoCleanup(): void {
        if (this.cleanupTimer) {
            clearInterval(this.cleanupTimer);
        }

        if (this.config.autoCleanupInterval) {
            this.cleanupTimer = setInterval(() => {
                this.cleanupExpired();
            }, this.config.autoCleanupInterval);

            console.log(`[CacheService] Auto cleanup scheduled every ${this.config.autoCleanupInterval / 1000} seconds`);
        }
    }

    /**
     * Stop automatic cleanup timer
     */
    public stopAutoCleanup(): void {
        if (this.cleanupTimer) {
            clearInterval(this.cleanupTimer);
            this.cleanupTimer = null;
            console.log('[CacheService] Auto cleanup stopped');
        }
    }

    /**
     * Enforce maximum cache size by removing oldest entries
     */
    public async enforceMaxEntries(): Promise<number> {
        try {
            if (!this.config.maxEntries) return 0;

            const db = await this.initDb();

            // Get all entries sorted by timestamp (oldest first)
            const allEntries = await db.getAllFromIndex('cacheEntries', 'by-expiration');

            if (allEntries.length <= this.config.maxEntries) {
                return 0;
            }

            // Calculate how many entries to remove
            const entriesToRemove = allEntries.length - this.config.maxEntries;

            // Sort by timestamp and remove oldest entries
            const sortedEntries = allEntries.sort((a, b) => a.timestamp - b.timestamp);
            const entriesForRemoval = sortedEntries.slice(0, entriesToRemove);

            for (const entry of entriesForRemoval) {
                await db.delete('cacheEntries', entry.path);
            }

            console.log(`[CacheService] Removed ${entriesForRemoval.length} oldest cache entries to enforce max size`);
            return entriesForRemoval.length;
        } catch (error) {
            console.error('[CacheService] Error enforcing max entries:', error);
            return 0;
        }
    }

    /**
     * Get cache statistics
     */
    public async getStats(): Promise<{
        totalEntries: number;
        expiredEntries: number;
        documentEntries: number;
        collectionEntries: number;
        oldestEntry: number;
        newestEntry: number;
    }> {
        try {
            const db = await this.initDb();
            const allEntries = await db.getAll('cacheEntries');
            const currentTime = Date.now();

            const expiredEntries = allEntries.filter(entry => entry.expiresAt < currentTime);
            const documentEntries = allEntries.filter(entry => entry.type === 'document');
            const collectionEntries = allEntries.filter(entry => entry.type === 'collection');

            // Find oldest and newest entries
            let oldestTimestamp = Number.MAX_SAFE_INTEGER;
            let newestTimestamp = 0;

            for (const entry of allEntries) {
                if (entry.timestamp < oldestTimestamp) {
                    oldestTimestamp = entry.timestamp;
                }

                if (entry.timestamp > newestTimestamp) {
                    newestTimestamp = entry.timestamp;
                }
            }

            return {
                totalEntries: allEntries.length,
                expiredEntries: expiredEntries.length,
                documentEntries: documentEntries.length,
                collectionEntries: collectionEntries.length,
                oldestEntry: oldestTimestamp === Number.MAX_SAFE_INTEGER ? 0 : oldestTimestamp,
                newestEntry: newestTimestamp
            };
        } catch (error) {
            console.error('[CacheService] Error getting cache stats:', error);
            return {
                totalEntries: 0,
                expiredEntries: 0,
                documentEntries: 0,
                collectionEntries: 0,
                oldestEntry: 0,
                newestEntry: 0
            };
        }
    }
}

// Export singleton instance
export const cacheService = CacheService.getInstance(); 