export const COLLECTIONS = {
    COMPANIES: 'companies',
    COMPANY_USERS: 'companyUsers',                             // companies/{companyId}/company_users
    CUSTOMERS: 'customers',                                     // companies/{companyId}/customers
    MANUFACTURING_FACILITIES: 'manufacturingFacilities',        // companies/{companyId}/manufacturingFacilities
    USED_SERIAL_NUMBERS: 'usedSerialNumbers',                   // companies/{companyId}/usedSerialNumbers
    PAPER_QUALITY_CHECKS: 'paperQualityChecks',                 // companies/{companyId}/paperQualityChecks
    PROJECTS: 'projects',                                       // companies/{companyId}/projects
    ORDERS: 'orders',                                           // companies/{companyId}/projects/{projectId}/orders
    BATCHES: 'batches',                                         // companies/{companyId}/projects/{projectId}/batches
    PRODUCTION_STAGES: 'productionStages',                      // companies/{companyId}/projects/{projectId}/productionStages
    PROD_STAGE_TIME_MANAGEMENT: 'prodStagesTimeManagement',     // companies/{companyId}/projects/{projectId}/prodStageTimeManagement
    TASKS: 'tasks',                                             // companies/{companyId}/tasks
    CHECKLIST: 'checklist',                                     // companies/{companyId}/projects/{projectId}/productionStages/{productionStageId}/checklist
    QUALITY_CHECKS: 'qualityChecks',                            // companies/{companyId}/qualityChecks
    ANSWERS: 'answers',                                         // companies/{companyId}/qualityChecks/{qualityCheckId}/answers
    DIRECT_USER_FEEDBACK: 'directUserFeedback',
    GLOBAL_SERIAL_NUMBERS: 'globalSerialNumbers',
    NEW_USERS_REQUESTS: 'newUsersRequests',
    REQUESTS: 'requests',                                      // companies/{companyId}/newUsersRequests/{requestId}/requests
    PROJECTS_THUMBNAILS: 'projectsThumbnails',
    RESERVED_SERIAL_NUMBERS: 'reservedSerialNumbers',
    USERS: 'users',
    SUPPLIERS: 'suppliers',
    ALL_SUPPLIERS: 'allSuppliers',
    DAILY_STATISTICS: 'dailyStats',                                 // statistics/daily
} as const

export const PATHS = {
    collections: {
        companies: () => COLLECTIONS.COMPANIES,
        companyUsers: (companyId: string) => `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.COMPANY_USERS}`,
        customers: (companyId: string) => `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CUSTOMERS}`,
        manufacturingFacilities: (companyId: string) => `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.MANUFACTURING_FACILITIES}`,
        usedSerialNumbers: (companyId: string) => `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.USED_SERIAL_NUMBERS}`,
        paperQualityChecks: (companyId: string) => `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.PAPER_QUALITY_CHECKS}`,
        projects: (companyId: string) => `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.PROJECTS}`,
        orders: (companyId: string, projectId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.PROJECTS}/${projectId}/${COLLECTIONS.ORDERS}`,
        batches: (companyId: string, projectId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.PROJECTS}/${projectId}/${COLLECTIONS.BATCHES}`,
        productionStages: (companyId: string, projectId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.PROJECTS}/${projectId}/${COLLECTIONS.PRODUCTION_STAGES}`,
        prodStageTimeManagement: (companyId: string, projectId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.PROJECTS}/${projectId}/${COLLECTIONS.PROD_STAGE_TIME_MANAGEMENT}`,
        tasks: (companyId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.TASKS}`,
        checklist: (companyId: string, projectId: string, productionStageId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.PROJECTS}/${projectId}/${COLLECTIONS.PRODUCTION_STAGES}/${productionStageId}/${COLLECTIONS.CHECKLIST}`,
        qualityChecks: (companyId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.QUALITY_CHECKS}`,
        qualityCheckAnswers: (companyId: string, qualityCheckId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.QUALITY_CHECKS}/${qualityCheckId}/${COLLECTIONS.ANSWERS}`,
        directUserFeedback: () => COLLECTIONS.DIRECT_USER_FEEDBACK,
        globalSerialNumbers: () => COLLECTIONS.GLOBAL_SERIAL_NUMBERS,
        newUsersRequests: (companyId: string) => `${COLLECTIONS.NEW_USERS_REQUESTS}/${companyId}/${COLLECTIONS.REQUESTS}`,
        projectsThumbnails: () => COLLECTIONS.PROJECTS_THUMBNAILS,
        users: () => COLLECTIONS.USERS,
        suppliers: (companyId: string) => `${COLLECTIONS.SUPPLIERS}/${companyId}/${COLLECTIONS.ALL_SUPPLIERS}`,
        dailyStatistics: () => `${COLLECTIONS.DAILY_STATISTICS}`,
    },
    documents: {
        company: (companyId: string) =>
            `${COLLECTIONS.COMPANIES}/${companyId}`,
        companyUser: (companyId: string, userId: string) =>
            `${PATHS.collections.companyUsers(companyId)}/${userId}`,
        customer: (companyId: string, customerId: string) =>
            `${PATHS.collections.customers(companyId)}/${customerId}`,
        manufacturingFacility: (companyId: string, manufacturingFacilityId: string) =>
            `${PATHS.collections.manufacturingFacilities(companyId)}/${manufacturingFacilityId}`,
        usedSerialNumber: (companyId: string, serialNumber: string) =>
            `${PATHS.collections.usedSerialNumbers(companyId)}/${serialNumber}`,
        paperQualityCheck: (companyId: string, paperQualityCheckId: string) =>
            `${PATHS.collections.paperQualityChecks(companyId)}/${paperQualityCheckId}`,
        project: (companyId: string, projectId: string) =>
            `${PATHS.collections.projects(companyId)}/${projectId}`,
        order: (companyId: string, projectId: string, orderId: string) =>
            `${PATHS.collections.orders(companyId, projectId)}/${orderId}`,
        batch: (companyId: string, projectId: string, batchId: string) =>
            `${PATHS.collections.batches(companyId, projectId)}/${batchId}`,
        productionStage: (companyId: string, projectId: string, productionStageId: string) =>
            `${PATHS.collections.productionStages(companyId, projectId)}/${productionStageId}`,
        task: (companyId: string, taskId: string) =>
            `${PATHS.collections.tasks(companyId)}/${taskId}`,
        prodStageTimeManagement: (companyId: string, projectId: string, orderId: string) =>
            `${PATHS.collections.prodStageTimeManagement(companyId, projectId)}/${orderId}`,
        checklist: (companyId: string, projectId: string, productionStageId: string, checklistId: string) =>
            `${PATHS.collections.checklist(companyId, projectId, productionStageId)}/${checklistId}`,
        qualityCheck: (companyId: string, qualityCheckId: string) =>
            `${PATHS.collections.qualityChecks(companyId)}/${qualityCheckId}`,
        qualityCheckAnswer: (companyId: string, qualityCheckId: string, answerId: string) =>
            `${PATHS.collections.qualityCheckAnswers(companyId, qualityCheckId)}/${answerId}`,
        directUserFeedback: (feedbackId: string) =>
            `${COLLECTIONS.DIRECT_USER_FEEDBACK}/${feedbackId}`,
        globalSerialNumber: (serialNumber: string) =>
            `${COLLECTIONS.GLOBAL_SERIAL_NUMBERS}/${serialNumber}`,
        newUsersRequest: (companyId: string, requestId: string) =>
            `${PATHS.collections.newUsersRequests(companyId)}/${requestId}`,
        projectThumbnail: (projectId: string) =>
            `${COLLECTIONS.PROJECTS_THUMBNAILS}/${projectId}`,
        reservedSerialNumbers: (companyId: string) =>
            `${COLLECTIONS.RESERVED_SERIAL_NUMBERS}/${companyId}`,
        user: (userId: string) =>
            `${COLLECTIONS.USERS}/${userId}`,
        supplier: (companyId: string, supplierId: string) =>
            `${PATHS.collections.suppliers(companyId)}/${supplierId}`,
        dailyStatistic: (date: string) =>
            `${PATHS.collections.dailyStatistics()}/${date}`,
    }
}