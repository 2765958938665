import { deleteField } from "firebase/firestore";


/**
 * Removes undefined fields from an object (including nested objects and arrays)
 * @param obj - The input object to clean
 * @returns A new object with all undefined fields removed
 */
export function removeUndefined<T>(obj: T): T {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj
      .map((item) => removeUndefined(item))
      .filter((item) => item !== undefined) as T;
  }

  if (typeof obj === "object") {
    const result = Object.entries(obj).reduce(
      (acc, [key, value]) => {
        const cleanValue = removeUndefined(value);
        if (cleanValue !== undefined) {
          // If it's an empty object (after cleaning), don't include it
          if (typeof cleanValue === "object" && !Array.isArray(cleanValue) &&
            cleanValue !== null && Object.keys(cleanValue).length === 0) {
            return acc;
          }
          acc[key] = cleanValue;
        }
        return acc;
      },
      {} as { [key: string]: any },
    );
    return result as T;
  }

  return obj;
}


/**
 * Compares two objects and returns an object containing the differences between them for firestore updates
 * @param oldObj - The original object to compare from
 * @param newObj - The new object to compare against
 * @param prefix - The prefix to use for the keys in the returned object. For recursive use only
 * @returns An object containing the differences, with each changed field having oldValue and newValue
 * @example
 * const old = { name: "John", age: 30, contact: { email: "john@example.com", phone: "1234567890", country: "US" } };
 * const new = { name: "Jane", age: 30, contact: { email: "jane@example.com", phone: "1234555555" } };
 * getFirestoreDiff(old, new); // { name: "Jane", contact.email: "jane@example.com", contact.phone: "1234555555", contact.country: "__DELETE__" }
 */
export function getFirestoreDiff<T extends Record<string, any>>(
  oldObj: T,
  newObj: T,
  prefix = ""
): Record<string, any> {
  const diff: Record<string, any> = {};
  const safeOldObj = oldObj || {};
  const safeNewObj = newObj || {};

  // Helper function to check if an object is empty or contains only undefined values
  const isEffectivelyEmpty = (obj: any): boolean => {
    if (!obj || typeof obj !== 'object') return true;
    if (Array.isArray(obj)) return obj.length === 0;
    return Object.entries(obj).every(([_, value]) => {
      if (value === undefined) return true;
      if (value === null) return false;
      if (typeof value === 'object') return isEffectivelyEmpty(value);
      return false;
    });
  };

  // Helper function to clean undefined values from an object
  const cleanObject = (obj: any): any => {
    if (!obj || typeof obj !== 'object') return obj;
    if (Array.isArray(obj)) return obj;

    const cleaned: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value === undefined) continue;
      if (value !== null && typeof value === 'object') {
        const cleanedValue = cleanObject(value);
        if (Object.keys(cleanedValue).length > 0) {
          cleaned[key] = cleanedValue;
        }
      } else {
        cleaned[key] = value;
      }
    }
    return cleaned;
  };

  for (const key of Object.keys({ ...safeOldObj, ...safeNewObj })) {
    const fullKey = prefix ? `${prefix}.${key}` : key;
    const oldValue = safeOldObj[key];
    const newValue = safeNewObj[key];

    // Handle arrays
    if (Array.isArray(oldValue) || Array.isArray(newValue)) {
      const oldArray = Array.isArray(oldValue) ? oldValue : [];
      const newArray = Array.isArray(newValue) ? newValue : [];
      if (JSON.stringify(oldArray) !== JSON.stringify(newArray)) {
        diff[fullKey] = newValue === undefined ? deleteField() : newArray;
      }
      continue;
    }

    // Handle objects
    if (oldValue !== null && typeof oldValue === 'object' &&
      newValue !== null && typeof newValue === 'object') {
      const nestedDiff = getFirestoreDiff(oldValue, newValue, fullKey);
      Object.assign(diff, nestedDiff);
      continue;
    }

    // Handle object to undefined/null transition
    if (oldValue !== null && typeof oldValue === 'object' &&
      (newValue === undefined || newValue === null)) {
      diff[fullKey] = deleteField();
      continue;
    }

    // Handle undefined/null to object transition
    if ((oldValue === undefined || oldValue === null) &&
      newValue !== null && typeof newValue === 'object') {
      const cleanedNewValue = cleanObject(newValue);
      if (!isEffectivelyEmpty(cleanedNewValue)) {
        diff[fullKey] = cleanedNewValue;
      }
      continue;
    }

    // Handle undefined values
    if (newValue === undefined) {
      if (oldValue !== undefined) {
        diff[fullKey] = deleteField();
      }
      continue;
    }

    // Handle primitive value changes
    if (oldValue !== newValue) {
      diff[fullKey] = newValue;
    }
  }

  console.log("[getFirestoreDiff] diff", diff);

  return diff;
}

