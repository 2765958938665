import { nanoid } from 'nanoid';
import {
    FirestoreDoc,
    QueryConstraintInput,
    subscribeToCollectionWithRetry,
    subscribeToDocumentWithRetry
} from '../firebaseCRUD';
import { RetryOptions, defaultRetryOptions } from '../firebaseRetryLogic';
import { DocumentData } from 'firebase/firestore';
import { cacheService } from './CacheService';

/**
 * Types of subscriptions supported by the service
 */
export type SubscriptionType = 'document' | 'collection';

/**
 * Status of a subscription
 */
export type SubscriptionStatus = 'initializing' | 'active' | 'error' | 'closed';

/**
 * Base options for all subscription types
 */
export interface SubscriptionOptions {
    onData?: (data: any) => void;
    onError?: (error: Error) => void;
    retryOptions?: Partial<RetryOptions>;
    cacheResults?: boolean;
    useCache?: boolean;
    cacheTTL?: number;
}

/**
 * Options specific to collection subscriptions
 */
export interface CollectionSubscriptionOptions extends SubscriptionOptions {
    queryConstraints?: QueryConstraintInput[];
    filterFunction?: (data: FirestoreDoc<any>[]) => FirestoreDoc<any>[];
}

/**
 * Information about a subscription
 */
export interface SubscriptionInfo {
    id: string;
    path: string;
    type: SubscriptionType;
    status: SubscriptionStatus;
    referenceCount: number;
    startTime: Date;
    lastDataTime?: Date;
    queryKey?: string;
}

/**
 * Interface for a subscription object
 */
export interface Subscription<T> {
    id: string;
    unsubscribe: () => void;
    getData: () => T | T[] | null;
    getStatus: () => SubscriptionStatus;
}

/**
 * Subscription for a single document
 */
export interface DocumentSubscription<T> extends Subscription<T> {
    getData: () => FirestoreDoc<T> | null;
}

/**
 * Subscription for a collection of documents
 */
export interface CollectionSubscription<T> extends Subscription<T> {
    getData: () => FirestoreDoc<T>[];
}

/**
 * Key used to identify a subscription in the registry
 * For collections with query constraints, includes a serialized version of the constraints
 */
type SubscriptionKey = string;

/**
 * Internal representation of a subscription
 */
interface SubscriptionEntry<T = any> {
    id: string;
    path: string;
    type: SubscriptionType;
    status: SubscriptionStatus;
    referenceCount: number;
    startTime: Date;
    lastDataTime?: Date;
    unsubscribeFn: () => void;
    cachedData: T | null;
    callbacks: Set<{
        onData?: (data: any) => void;
        onError?: (error: Error) => void;
    }>;
    queryConstraints?: QueryConstraintInput[];
    queryKey?: string;
}

/**
 * Service to manage Firebase subscriptions
 * Prevents duplicate subscriptions to the same path and provides additional features
 */
class SubscriptionService {
    private static instance: SubscriptionService;
    private subscriptions: Map<SubscriptionKey, SubscriptionEntry> = new Map();
    private throttleTimeouts: Map<string, NodeJS.Timeout> = new Map();
    private throttleDelay = 300; // ms to throttle rapid subscription changes

    private constructor() {
        // Private constructor to enforce singleton pattern
    }

    /**
     * Get the singleton instance of the service
     */
    public static getInstance(): SubscriptionService {
        if (!SubscriptionService.instance) {
            SubscriptionService.instance = new SubscriptionService();
        }
        return SubscriptionService.instance;
    }

    /**
     * Generate a unique subscription ID
     */
    private generateSubscriptionId(): string {
        return nanoid(10);
    }

    /**
     * Generate a key for subscription registry lookup
     * For collections with query constraints, includes a serialized version of the constraints
     */
    private getSubscriptionKey(path: string, type: SubscriptionType, queryConstraints?: QueryConstraintInput[]): SubscriptionKey {
        if (type === 'collection' && queryConstraints && queryConstraints.length > 0) {
            // Sort to ensure consistent key regardless of constraints order
            const sortedConstraints = [...queryConstraints].sort((a, b) => {
                if ('field' in a && 'field' in b) {
                    return a.field.localeCompare(b.field);
                }
                return 0;
            });
            return `${path}:${type}:${JSON.stringify(sortedConstraints)}`;
        }
        return `${path}:${type}`;
    }

    /**
     * Subscribe to a document
     */
    public async subscribeToDocument<T extends DocumentData>(
        path: string,
        options: SubscriptionOptions = {}
    ): Promise<DocumentSubscription<T>> {
        const subscriptionKey = this.getSubscriptionKey(path, 'document');

        // Check if we already have this subscription
        if (this.subscriptions.has(subscriptionKey)) {
            return this.attachToExistingSubscription<T>(subscriptionKey, options) as DocumentSubscription<T>;
        }

        // Create new subscription
        return this.throttleSubscription<T>(() =>
            this.createDocumentSubscription<T>(path, subscriptionKey, options)
        ) as Promise<DocumentSubscription<T>>;
    }

    /**
     * Subscribe to a collection
     */
    public async subscribeToCollection<T extends DocumentData>(
        path: string,
        options: CollectionSubscriptionOptions = {}
    ): Promise<CollectionSubscription<T>> {
        const { queryConstraints, ...otherOptions } = options;
        const subscriptionKey = this.getSubscriptionKey(path, 'collection', queryConstraints);

        // Check if we already have this subscription
        if (this.subscriptions.has(subscriptionKey)) {
            return this.attachToExistingSubscription<T>(subscriptionKey, otherOptions) as CollectionSubscription<T>;
        }

        // Create new subscription
        return this.throttleSubscription<T>(() =>
            this.createCollectionSubscription<T>(path, subscriptionKey, options)
        ) as Promise<CollectionSubscription<T>>;
    }

    /**
     * Unsubscribe from a specific subscription by ID
     */
    public unsubscribe(subscriptionId: string): void {
        let keyToRemove: string | undefined;
        let entryToRemove: SubscriptionEntry | undefined;

        // Find the subscription entry
        for (const [key, entry] of this.subscriptions.entries()) {
            if (entry.id === subscriptionId) {
                keyToRemove = key;
                entryToRemove = entry;
                break;
            }
        }

        if (!keyToRemove || !entryToRemove) {
            console.warn(`[SubscriptionService] Attempted to unsubscribe from unknown subscription: ${subscriptionId}`);
            return;
        }

        // Remove callback by comparing subscription IDs
        entryToRemove.callbacks = new Set(
            [...entryToRemove.callbacks].filter(cb => cb !== subscriptionId)
        );

        // Decrease reference count
        entryToRemove.referenceCount--;

        console.log(`[SubscriptionService] Unsubscribed from ${entryToRemove.path} (${entryToRemove.type}). References remaining: ${entryToRemove.referenceCount}`);

        // If no more references, clean up the subscription
        if (entryToRemove.referenceCount <= 0) {
            entryToRemove.unsubscribeFn();
            entryToRemove.status = 'closed';
            this.subscriptions.delete(keyToRemove);
            console.log(`[SubscriptionService] Removed subscription for ${entryToRemove.path} (${entryToRemove.type})`);
        }
    }

    /**
     * Get information about a specific subscription
     */
    public getSubscriptionStatus(subscriptionId: string): SubscriptionStatus | null {
        for (const entry of this.subscriptions.values()) {
            if (entry.id === subscriptionId) {
                return entry.status;
            }
        }
        return null;
    }

    /**
     * Get information about all active subscriptions
     */
    public getActiveSubscriptions(): SubscriptionInfo[] {
        return Array.from(this.subscriptions.values()).map(entry => ({
            id: entry.id,
            path: entry.path,
            type: entry.type,
            status: entry.status,
            referenceCount: entry.referenceCount,
            startTime: entry.startTime,
            lastDataTime: entry.lastDataTime,
            queryKey: entry.queryKey
        }));
    }

    /**
     * Clear all subscriptions
     */
    public clearAllSubscriptions(): void {
        for (const entry of this.subscriptions.values()) {
            entry.unsubscribeFn();
        }
        this.subscriptions.clear();
        console.log('[SubscriptionService] Cleared all subscriptions');
    }

    /**
     * Throttle subscription creation to prevent rapid changes
     */
    private async throttleSubscription<T>(createFn: () => Promise<Subscription<T>>): Promise<Subscription<T>> {
        // For now, we don't actually throttle during creation
        // This is a placeholder for potential future implementation
        return createFn();
    }

    /**
     * Create a subscription to a document
     */
    private async createDocumentSubscription<T extends DocumentData>(
        path: string,
        subscriptionKey: string,
        options: SubscriptionOptions
    ): Promise<DocumentSubscription<T>> {
        const subscriptionId = this.generateSubscriptionId();
        const {
            onData,
            onError,
            retryOptions,
            cacheResults = true,
            useCache = true,
            cacheTTL
        } = options;

        // Create the subscription entry
        const subscriptionEntry: SubscriptionEntry<FirestoreDoc<T> | null> = {
            id: subscriptionId,
            path,
            type: 'document',
            status: 'initializing',
            referenceCount: 1,
            startTime: new Date(),
            cachedData: null,
            callbacks: new Set([{ onData, onError }]),
            unsubscribeFn: () => { }
        };

        // Try to load from cache first if enabled
        if (useCache) {
            try {
                const cachedData = await cacheService.get<FirestoreDoc<T> | null>(path, 'document');

                if (cachedData !== null) {
                    console.log(`[SubscriptionService] Using cached data for document ${path}`);
                    subscriptionEntry.cachedData = cachedData;

                    // Notify callback about cached data
                    if (onData) {
                        setTimeout(() => onData(cachedData), 0);
                    }
                }
            } catch (error) {
                console.error('[SubscriptionService] Error loading from cache:', error);
            }
        }

        // Handle data updates
        const handleData = (data: FirestoreDoc<T> | null) => {
            subscriptionEntry.status = 'active';
            subscriptionEntry.lastDataTime = new Date();

            // Update in-memory cache if caching is enabled
            if (cacheResults) {
                subscriptionEntry.cachedData = data;

                // Update persistent cache (IndexedDB)
                if (data !== null) {
                    cacheService.set<FirestoreDoc<T> | null>(path, data, 'document', undefined, cacheTTL)
                        .catch(error => console.error('[SubscriptionService] Error updating cache:', error));
                }
            }

            // Notify all callbacks
            for (const callback of subscriptionEntry.callbacks) {
                if (callback.onData) {
                    try {
                        callback.onData(data);
                    } catch (error) {
                        console.error('[SubscriptionService] Error in onData callback:', error);
                    }
                }
            }
        };

        // Handle errors
        const handleError = (error: Error) => {
            subscriptionEntry.status = 'error';

            // Notify all callbacks
            for (const callback of subscriptionEntry.callbacks) {
                if (callback.onError) {
                    try {
                        callback.onError(error);
                    } catch (callbackError) {
                        console.error('[SubscriptionService] Error in onError callback:', callbackError);
                    }
                }
            }
        };

        // Create the actual Firebase subscription
        const finalRetryOptions = { ...defaultRetryOptions, ...retryOptions };
        const unsubscribeFn = subscribeToDocumentWithRetry<T>(
            path,
            handleData,
            handleError,
            finalRetryOptions
        );

        // Store the unsubscribe function
        subscriptionEntry.unsubscribeFn = unsubscribeFn;

        // Add to registry
        this.subscriptions.set(subscriptionKey, subscriptionEntry);

        console.log(`[SubscriptionService] Created document subscription for ${path}`);

        // Return the subscription object
        return {
            id: subscriptionId,
            unsubscribe: () => this.unsubscribe(subscriptionId),
            getData: () => subscriptionEntry.cachedData as FirestoreDoc<T> | null,
            getStatus: () => subscriptionEntry.status
        };
    }

    /**
     * Create a subscription to a collection
     */
    private async createCollectionSubscription<T extends DocumentData>(
        path: string,
        subscriptionKey: string,
        options: CollectionSubscriptionOptions
    ): Promise<CollectionSubscription<T>> {
        const subscriptionId = this.generateSubscriptionId();
        const {
            onData,
            onError,
            retryOptions,
            cacheResults = true,
            useCache = true,
            cacheTTL,
            queryConstraints,
            filterFunction
        } = options;

        // Create the subscription entry
        const subscriptionEntry: SubscriptionEntry<FirestoreDoc<T>[]> = {
            id: subscriptionId,
            path,
            type: 'collection',
            status: 'initializing',
            referenceCount: 1,
            startTime: new Date(),
            cachedData: [],
            callbacks: new Set([{ onData, onError }]),
            unsubscribeFn: () => { },
            queryConstraints,
            queryKey: queryConstraints ? JSON.stringify(queryConstraints) : undefined
        };

        // Try to load from cache first if enabled
        if (useCache) {
            try {
                const queryKey = subscriptionEntry.queryKey;
                const cachedData = await cacheService.get<FirestoreDoc<T>[]>(path, 'collection', queryKey);

                if (cachedData !== null && cachedData.length > 0) {
                    console.log(`[SubscriptionService] Using cached data for collection ${path} (${cachedData.length} items)`);

                    // Apply filter function if provided
                    const filteredData = filterFunction ? filterFunction(cachedData) : cachedData;
                    subscriptionEntry.cachedData = filteredData;

                    // Notify callback about cached data
                    if (onData) {
                        setTimeout(() => onData(filteredData), 0);
                    }
                }
            } catch (error) {
                console.error('[SubscriptionService] Error loading from cache:', error);
            }
        }

        // Handle data updates
        const handleData = (data: FirestoreDoc<T>[]) => {
            subscriptionEntry.status = 'active';
            subscriptionEntry.lastDataTime = new Date();

            // Apply filter function if provided
            const filteredData = filterFunction ? filterFunction(data) : data;

            // Update in-memory cache if caching is enabled
            if (cacheResults) {
                subscriptionEntry.cachedData = filteredData;

                // Update persistent cache (IndexedDB)
                cacheService.set<FirestoreDoc<T>[]>(
                    path,
                    filteredData,
                    'collection',
                    subscriptionEntry.queryKey,
                    cacheTTL
                ).catch(error => console.error('[SubscriptionService] Error updating cache:', error));
            }

            // Notify all callbacks
            for (const callback of subscriptionEntry.callbacks) {
                if (callback.onData) {
                    try {
                        callback.onData(filteredData);
                    } catch (error) {
                        console.error('[SubscriptionService] Error in onData callback:', error);
                    }
                }
            }
        };

        // Handle errors
        const handleError = (error: Error) => {
            subscriptionEntry.status = 'error';

            // Notify all callbacks
            for (const callback of subscriptionEntry.callbacks) {
                if (callback.onError) {
                    try {
                        callback.onError(error);
                    } catch (callbackError) {
                        console.error('[SubscriptionService] Error in onError callback:', callbackError);
                    }
                }
            }
        };

        // Create the actual Firebase subscription
        const finalRetryOptions = { ...defaultRetryOptions, ...retryOptions };
        const unsubscribeFn = subscribeToCollectionWithRetry<T>(
            path,
            handleData,
            handleError,
            queryConstraints,
            finalRetryOptions
        );

        // Store the unsubscribe function
        subscriptionEntry.unsubscribeFn = unsubscribeFn;

        // Add to registry
        this.subscriptions.set(subscriptionKey, subscriptionEntry);

        console.log(`[SubscriptionService] Created collection subscription for ${path}`);

        // Return the subscription object
        return {
            id: subscriptionId,
            unsubscribe: () => this.unsubscribe(subscriptionId),
            getData: () => subscriptionEntry.cachedData as FirestoreDoc<T>[],
            getStatus: () => subscriptionEntry.status
        };
    }

    /**
     * Attach to an existing subscription
     */
    private attachToExistingSubscription<T>(
        subscriptionKey: string,
        options: SubscriptionOptions
    ): Subscription<T> {
        const existingEntry = this.subscriptions.get(subscriptionKey)!;
        const subscriptionId = this.generateSubscriptionId();
        const { onData, onError } = options;

        // Increase reference count
        existingEntry.referenceCount++;

        // Add callback
        existingEntry.callbacks.add({ onData, onError });

        console.log(`[SubscriptionService] Reusing existing subscription for ${existingEntry.path} (${existingEntry.type}). Reference count: ${existingEntry.referenceCount}`);

        // If there's cached data and a callback, immediately invoke it
        if (existingEntry.cachedData !== null && onData) {
            setTimeout(() => onData(existingEntry.cachedData), 0);
        }

        // Return the subscription object
        if (existingEntry.type === 'document') {
            return {
                id: subscriptionId,
                unsubscribe: () => this.unsubscribe(subscriptionId),
                getData: () => existingEntry.cachedData as FirestoreDoc<T> | null,
                getStatus: () => existingEntry.status
            } as DocumentSubscription<T>;
        } else {
            return {
                id: subscriptionId,
                unsubscribe: () => this.unsubscribe(subscriptionId),
                getData: () => existingEntry.cachedData as FirestoreDoc<T>[],
                getStatus: () => existingEntry.status
            } as CollectionSubscription<T>;
        }
    }

    /**
     * Clear cache for specific path
     */
    public async clearCache(path: string, type: SubscriptionType, queryKey?: string): Promise<void> {
        await cacheService.delete(path, type, queryKey);
    }

    /**
     * Clear all cache data
     */
    public async clearAllCache(): Promise<boolean> {
        return await cacheService.clearAll();
    }

    /**
     * Get cache statistics
     */
    public async getCacheStats() {
        return cacheService.getStats();
    }
}

// Export singleton instance
export const subscriptionService = SubscriptionService.getInstance(); 