import {
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    QuerySnapshot,
    DocumentData,
    writeBatch,
    DocumentReference,
    query,
    where,
    orderBy,
    limit,
    WhereFilterOp,
    OrderByDirection,
    Query,
    QueryConstraint as FirestoreQueryConstraint,
    WithFieldValue,
    updateDoc,
    setDoc,
} from 'firebase/firestore';
import { db } from './firebase';
import { DateService } from '../services/date.service';
import { getFirestoreDiff, removeUndefined } from './firebaseFunctions';
import { getSetHistoryLogWithPath, getUpdateHistoryLogOperation } from './services/historyLogsService';
import { defaultRetryOptions, retryDelay, RetryOptions, withRetry } from './firebaseRetryLogic';

// Generic type for document data
export type FirestoreDoc<T> = T & { id: string };

/**
 * Updates multiple documents in Firestore with history tracking (for each document).
 * Creates a diff between old and new data, and stores update history in a separate collection.
 * 
 * @param documents - Array of objects containing document path, old data, new data, and user ID
 * @returns Promise that resolves when updates are complete
 * @throws Error if batch update fails
 */
export const updateDocumentsWithHistory = async <T extends DocumentData>(
    documents: Array<{
        path: string,
        oldData: T,
        newData: T,
        userId: string
    }>,
    retryOptions?: Partial<RetryOptions>
): Promise<void> => {
    return withRetry(async () => {
        const batchOperations: BatchOperation[] = [];

        for (const document of documents) {
            const firestoreDiffs = getFirestoreDiff(document.oldData, document.newData);
            if (Object.keys(firestoreDiffs).length === 0) {
                console.log("[updateDocumentsWithHistory] No changes to update for document", document.path);
                continue;
            }
            const historyLogOp = getUpdateHistoryLogOperation(
                document.oldData,
                document.newData,
                document.path,
                document.userId
            );
            const updateRef = doc(db, document.path);

            console.log("[updateDocumentsWithHistory] firestoreDiffs", firestoreDiffs);
            console.log("[updateDocumentsWithHistory] historyLogOp", historyLogOp);

            batchOperations.push(
                {
                    type: 'update',
                    ref: updateRef,
                    data: firestoreDiffs
                },
                {
                    type: 'set',
                    ref: historyLogOp.ref,
                    data: historyLogOp.data
                }
            );
        }

        await executeBatchOperations(batchOperations);
    }, retryOptions);
};

export const setDocumentsWithHistory = async <T extends DocumentData>(
    documents: Array<{
        path: string,
        data: Omit<T, "id"> & { id?: string },
        userId: string
    }>,
    retryOptions?: Partial<RetryOptions>
): Promise<FirestoreDoc<T>[]> => {
    return withRetry(async () => {
        const batchOperations: BatchOperation[] = [];
        const results: FirestoreDoc<T>[] = [];

        for (const document of documents) {
            const setRef = document.data.id ? doc(db, document.path, document.data.id) : doc(collection(db, document.path));
            const pathForHistoryLog = `${document.path}/${setRef.id}`;
            const historyLogOp = getSetHistoryLogWithPath(document.data, pathForHistoryLog, document.userId);
            const { id, ...dataWithoutId } = document.data;
            const dataWithoutUndefined = removeUndefined(dataWithoutId);
            console.log("[setDocumentsWithHistory] dataWithoutUndefined", dataWithoutUndefined);

            batchOperations.push(
                {
                    type: 'set',
                    ref: setRef,
                    data: dataWithoutUndefined
                },
                {
                    type: 'set',
                    ref: historyLogOp.ref,
                    data: historyLogOp.data
                }
            );

            results.push({
                id: setRef.id,
                ...dataWithoutUndefined
            } as FirestoreDoc<T>);
        }

        await executeBatchOperations(batchOperations);
        return results;
    }, retryOptions);
};

export const setDocumentWithHistory = async <T extends DocumentData>(
    {
        path,
        data,
        userId
    }: {
        path: string,
        data: Omit<T, "id"> & { id?: string },
        userId: string
    },
    retryOptions?: Partial<RetryOptions>
): Promise<FirestoreDoc<T>> => {
    const results = await setDocumentsWithHistory([{ path, data, userId }], retryOptions);
    return results[0];
};

export const softDeleteDocumentWithHistory = async <T extends DocumentData & { isSoftDeleted?: boolean }>(
    {
        path,
        userId,
    }: {
        path: string,
        userId: string,
    },
    retryOptions?: Partial<RetryOptions>
): Promise<void> => {
    withRetry(async () => {
        const oldObject: T = { isSoftDeleted: false } as T;
        const newObject: T = { isSoftDeleted: true } as T;
        const firestoreDiffs = getFirestoreDiff<T>(oldObject, newObject);
        const historyLogOp = getUpdateHistoryLogOperation(oldObject, newObject, path, userId);
        const updateRef = doc(db, path);

        await executeBatchOperations([
            {
                type: 'update',
                ref: updateRef,
                data: firestoreDiffs
            },
            {
                type: 'set',
                ref: historyLogOp.ref,
                data: historyLogOp.data
            }
        ]);
    }, retryOptions);
};

/**
 * Sets a document with a specific ID, without history tracking
 * @param path - The path to the document
 * @param data - The data to set
 * @param retryOptions - The retry options
 * @returns The document
 */
export const simpleSetDocument = async <T extends DocumentData>(
    {
        path,
        data,
        retryOptions
    }: {
        path: string,
        data: T,
        retryOptions?: Partial<RetryOptions>
    }
): Promise<void> => {
    return withRetry(async () => {
        let docRef: DocumentReference;
        let updatedData: T | Omit<T, "id"> = data;
        if (data.id) {
            docRef = doc(db, path, data.id);
            const { id, ...dataNoId } = data;
            updatedData = dataNoId;
        } else {
            docRef = doc(collection(db, path));
        }
        const result = await setDoc(docRef, {
            ...updatedData,
            isSoftDeleted: false,
            createdAt: DateService.now(),
            lastUpdatedAt: DateService.now()
        });
        return result;
    }, retryOptions);
};

/**
 * Updates a document with partial data, without history tracking
 * @param path - The path to the document
 * @param data - The data to update
 * @param retryOptions - The retry options
 * @returns The document
 */
export const simpleUpdateDocument = async <T extends DocumentData>(
    {
        path,
        data,
        retryOptions
    }: {
        path: string,
        data: Partial<WithFieldValue<T>>,
        retryOptions?: Partial<RetryOptions>
    }
): Promise<void> => {
    return withRetry(async () => {
        const docRef = doc(db, path);
        await updateDoc(docRef, {
            ...data as Partial<DocumentData>,
            lastUpdatedAt: DateService.now()
        });
    }, retryOptions);
};

// // Delete a document
// export const deleteDocument = async (
//     path: string
// ): Promise<void> => {
//     const docRef = doc(db, path);
//     await deleteDoc(docRef);
// };



// Fetch a single document
export const fetchDocument = async <T extends DocumentData>(
    path: string,
    retryOptions?: Partial<RetryOptions>
): Promise<FirestoreDoc<T> | null> => {
    return withRetry(async () => {
        const docRef = doc(db, path);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            return null;
        }

        return {
            ...docSnap.data() as T,
            id: docSnap.id,
        };
    }, retryOptions);
};

export type QueryConstraintInput = {
    field: string;
    operator: WhereFilterOp;
    value: any;
} | {
    field: string;
    direction: OrderByDirection;
    type: 'orderBy';
} | {
    value: number;
    type: 'limit';
};

// Fetch all documents in a collection
export const fetchCollection = async <T extends DocumentData>(
    {
        collectionPath,
        queryConstraints,
    }: {
        collectionPath: string,
        queryConstraints?: QueryConstraintInput[],
    },
    retryOptions?: Partial<RetryOptions>
): Promise<FirestoreDoc<T>[]> => {
    return withRetry(async () => {
        const collectionRef = collection(db, collectionPath);
        let queryRef: Query = collectionRef;

        if (queryConstraints) {
            const constraints = queryConstraints
                .map(constraint => {
                    if ('type' in constraint) {
                        if (constraint.type === 'orderBy') {
                            return orderBy(constraint.field, constraint.direction);
                        } else if (constraint.type === 'limit') {
                            return limit(constraint.value);
                        }
                    } else {
                        return where(constraint.field, constraint.operator, constraint.value);
                    }
                    return undefined;
                })
                .filter(Boolean);

            if (constraints.length > 0) {
                queryRef = query(collectionRef, ...constraints as FirestoreQueryConstraint[]);
            }
        }

        const querySnapshot = await getDocs(queryRef);

        return querySnapshot.docs.map((doc) => ({
            ...doc.data() as T,
            id: doc.id,
        }));
    }, retryOptions);
};

// Subscribe to all documents in a collection
const subscribeToCollection = <T extends DocumentData>(
    collectionName: string,
    onUpdate: (docs: FirestoreDoc<T>[]) => void,
    onError?: (error: Error) => void,
    queryConstraints?: QueryConstraintInput[]
) => {
    const collectionRef = collection(db, collectionName);
    let queryRef: Query = collectionRef;

    if (queryConstraints) {
        const constraints = queryConstraints
            .map(constraint => {
                if ('type' in constraint) {
                    if (constraint.type === 'orderBy') {
                        return orderBy(constraint.field, constraint.direction);
                    } else if (constraint.type === 'limit') {
                        return limit(constraint.value);
                    }
                } else {
                    return where(constraint.field, constraint.operator, constraint.value);
                }
                return undefined;
            })
            .filter(Boolean);

        if (constraints.length > 0) {
            queryRef = query(collectionRef, ...constraints as FirestoreQueryConstraint[]);
        }
    }

    return onSnapshot(
        queryRef,
        (snapshot: QuerySnapshot<DocumentData>) => {
            const docs = snapshot.docs.map((doc) => ({
                ...doc.data() as T,
                id: doc.id,
            }));
            onUpdate(docs);
        },
        (error) => {
            if (onError) {
                onError(error);
            } else {
                console.error('Error in collection subscription:', error);
            }
        }
    );
};

export const subscribeToCollectionWithRetry = <T extends DocumentData>(
    collectionName: string,
    onUpdate: (docs: FirestoreDoc<T>[]) => void,
    onError?: (error: Error) => void,
    queryConstraints?: QueryConstraintInput[],
    retryOptions?: Partial<RetryOptions>
) => {
    let unsubscribe: (() => void) | null = null;
    let isRetrying = false;
    let retryAttempt = 0;
    const finalOptions = { ...defaultRetryOptions, ...retryOptions };

    const subscribe = async () => {
        if (isRetrying) return;

        try {
            console.log(`[Firebase] Subscribing to collection: ${collectionName} (attempt ${retryAttempt + 1})`);
            unsubscribe = subscribeToCollection<T>(
                collectionName,
                onUpdate,
                async (error) => {
                    if (onError) onError(error);

                    // Check if we should retry
                    if (retryAttempt < finalOptions.maxAttempts && defaultRetryOptions.shouldRetry?.(error)) {
                        isRetrying = true;
                        retryAttempt++;
                        let delayTime = finalOptions.delayMs * Math.pow(finalOptions.backoffFactor, retryAttempt - 1);

                        console.warn(
                            `[Firebase] Subscription failed for collection ${collectionName}. ` +
                            `Attempt ${retryAttempt}/${finalOptions.maxAttempts}. ` +
                            `Retrying in ${delayTime}ms...`,
                            error
                        );
                        await retryDelay(delayTime);

                        isRetrying = false;
                        subscribe(); // Resubscribe
                    } else {
                        console.error(
                            `[Firebase] Subscription failed for collection ${collectionName} after ${retryAttempt + 1} attempts. ` +
                            `Max attempts (${finalOptions.maxAttempts + 1}) reached or error not retryable.`,
                            error
                        );
                    }
                },
                queryConstraints
            );
        } catch (error) {
            console.error(`[Firebase] Error setting up subscription for collection ${collectionName}:`, error);
            if (onError) onError(error as Error);
        }
    };

    subscribe();

    // Return unsubscribe function
    return () => {
        console.log(`[Firebase] Unsubscribing from collection: ${collectionName}`);
        if (unsubscribe) unsubscribe();
    };
};

export const subscribeToDocumentWithRetry = <T extends DocumentData>(
    docPath: string,
    onUpdate: (doc: FirestoreDoc<T> | null) => void,
    onError?: (error: Error) => void,
    retryOptions?: Partial<RetryOptions>
) => {
    let unsubscribe: (() => void) | null = null;
    let isRetrying = false;
    let retryAttempt = 0;
    const finalOptions = { ...defaultRetryOptions, ...retryOptions };

    const subscribe = async () => {
        if (isRetrying) return;

        try {
            console.log(`[Firebase] Subscribing to document: ${docPath} (attempt ${retryAttempt + 1})`);
            unsubscribe = subscribeToDocument<T>(
                docPath,
                onUpdate,
                async (error) => {
                    if (onError) onError(error);

                    // Check if we should retry
                    if (retryAttempt < finalOptions.maxAttempts && defaultRetryOptions.shouldRetry?.(error)) {
                        isRetrying = true;
                        retryAttempt++;
                        let delayTime = finalOptions.delayMs * Math.pow(finalOptions.backoffFactor, retryAttempt - 1);

                        console.warn(
                            `[Firebase] Subscription failed for document ${docPath}. ` +
                            `Attempt ${retryAttempt}/${finalOptions.maxAttempts}. ` +
                            `Retrying in ${delayTime}ms...`,
                            error
                        );
                        await retryDelay(delayTime);

                        isRetrying = false;
                        subscribe(); // Resubscribe
                    } else {
                        console.error(
                            `[Firebase] Subscription failed for document ${docPath} after ${retryAttempt} attempts. ` +
                            `Max attempts (${finalOptions.maxAttempts}) reached or error not retryable.`,
                            error
                        );
                    }
                }
            );
        } catch (error) {
            console.error(`[Firebase] Error setting up subscription for document ${docPath}:`, error);
            if (onError) onError(error as Error);
        }
    };

    subscribe();

    // Return unsubscribe function
    return () => {
        console.log(`[Firebase] Unsubscribing from document: ${docPath}`);
        if (unsubscribe) unsubscribe();
    };
};

const subscribeToDocument = <T extends DocumentData>(
    docPath: string,
    onUpdate: (doc: FirestoreDoc<T> | null) => void,
    onError?: (error: Error) => void
) => {
    const docRef = doc(db, docPath);

    return onSnapshot(
        docRef,
        (snapshot) => {
            if (!snapshot.exists()) {
                onUpdate(null);
                return;
            }

            onUpdate({
                ...snapshot.data() as T,
                id: snapshot.id,
            });
        },
        (error) => {
            if (onError) {
                onError(error);
            } else {
                console.error('Error in document subscription:', error);
            }
        }
    );
};

interface BatchOperation<T extends DocumentData = DocumentData> {
    type: 'update' | 'set' | 'delete';
    ref: DocumentReference<T>;
    data?: T;
}

// Batch update with history logs
const executeBatchOperations = async <T extends DocumentData>(operations: BatchOperation<T>[]): Promise<void> => {
    const batch = writeBatch(db);

    for (const operation of operations) {
        console.log("[executeBatchOperations] data", operation.data);
        switch (operation.type) {
            case 'update':
                if (operation.data) {
                    batch.update(operation.ref, {
                        ...(operation.data as DocumentData),
                        lastUpdatedAt: DateService.now()
                    });
                }
                break;
            case 'set':
                if (operation.data) {
                    batch.set(operation.ref, {
                        ...operation.data,
                        isSoftDeleted: false,
                        createdAt: DateService.now(),
                        lastUpdatedAt: DateService.now(),
                    });
                }
                break;
            case 'delete':
                batch.delete(operation.ref);
                break;
        }
    }

    await batch.commit();
};

