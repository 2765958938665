import { doc, DocumentReference, serverTimestamp, collection } from "firebase/firestore";
import { db } from "../firebase";
import { HistoryLog, HistoryLogChangeType } from "../../interfaces";
import { DateService } from "../../services/date.service";
import { removeUndefined } from "../firebaseFunctions";

export function getUpdateHistoryLogOperation<T extends Record<string, any>>(oldItem: T, newItem: T, docPath: string, userId: string): {
    ref: DocumentReference;
    data: HistoryLog<T>;
} {
    const historyLog = {
        changes: getChanges(oldItem, newItem),
        changedAtLocal: DateService.now(),
        changedAtServerTimestamp: serverTimestamp(),
        changedBy: userId,
        changeType: HistoryLogChangeType.UPDATE,
    };
    const historyLogsRef = doc(collection(db, `${docPath}/historyLogs`));
    return {
        ref: historyLogsRef,
        data: historyLog
    };
}

export function getSetHistoryLogWithPath<T extends Record<string, any>>(data: T, docPath: string, userId: string): {
    ref: DocumentReference;
    data: HistoryLog<T>;
} {
    const historyLog = {
        changes: getChanges({} as T, data),
        changedAtLocal: DateService.now(),
        changedAtServerTimestamp: serverTimestamp(),
        changedBy: userId,
        changeType: HistoryLogChangeType.SET,
    };
    const historyLogsRef = doc(collection(db, `${docPath}/historyLogs`));
    return {
        ref: historyLogsRef,
        data: historyLog
    };
}

// export async function addHistoryLog<T>(historyLog: HistoryLog<T>, docPath: string) {
//     const historyLogsCollection = collection(db, docPath, "historyLogs");
//     try {
//         await addDoc(historyLogsCollection, historyLog);
//     } catch (error) {
//         console.error("Error adding history log:", error);
//     }
// }

export function getChanges<T extends Record<string, any>>(oldObj: T, newObj: T): Partial<Record<keyof T, { oldValue: any; newValue: any }>> {
    const diff: any = {};

    // Helper function to check if an object is effectively empty (all values undefined or empty objects)
    function isEffectivelyEmpty(obj: any): boolean {
        if (!obj || typeof obj !== 'object') return true;
        return Object.entries(obj).every(([_, value]) => {
            if (value === undefined) return true;
            if (value === null) return false;
            if (typeof value === 'object') return isEffectivelyEmpty(value);
            return false;
        });
    }

    // Ensure we have objects to compare, defaulting to empty objects if undefined
    const safeOldObj = oldObj || {};
    const safeNewObj = newObj || {};

    for (const key of Object.keys({ ...safeOldObj, ...safeNewObj }) as Array<keyof T>) {
        const oldValue = safeOldObj[key];
        const newValue = safeNewObj[key];

        // Handle arrays
        if (Array.isArray(oldValue) || Array.isArray(newValue)) {
            const oldArray = Array.isArray(oldValue) ? oldValue : [];
            const newArray = Array.isArray(newValue) ? newValue : [];

            if (JSON.stringify(oldArray) !== JSON.stringify(newArray)) {
                diff[key] = {
                    oldValue: oldValue === undefined ? '[%undefined%]' : oldArray,
                    newValue: newValue === undefined ? '[%undefined%]' : newArray
                };
            }
            continue;
        }

        // Handle nested objects
        if (newValue !== null && typeof newValue === 'object' || oldValue !== null && typeof oldValue === 'object') {
            const safeOldNestedObj = (oldValue !== null && typeof oldValue === 'object') ? oldValue : {};
            const safeNewNestedObj = (newValue !== null && typeof newValue === 'object') ? newValue : {};

            const isOldObjEmpty = isEffectivelyEmpty(safeOldNestedObj);
            const isNewObjEmpty = isEffectivelyEmpty(safeNewNestedObj);

            // If both objects are effectively empty, skip
            if (isOldObjEmpty && isNewObjEmpty) {
                continue;
            }

            // If new object is empty but old isn't, mark as deleted
            if (isNewObjEmpty && newValue === undefined) {
                if (!isOldObjEmpty) {
                    diff[key] = {
                        oldValue: safeOldNestedObj,
                        newValue: '[%deleted%]'
                    };
                }
            }
            // If old object is empty but new isn't, treat old as undefined
            else if (isOldObjEmpty) {
                const nestedDiff = getChanges(safeOldNestedObj as any, safeNewNestedObj as any);
                if (Object.keys(nestedDiff).length > 0) {
                    diff[key] = nestedDiff;
                }
            }
            // Neither is empty, do normal comparison
            else {
                const nestedDiff = getChanges(safeOldNestedObj as any, safeNewNestedObj as any);
                if (Object.keys(nestedDiff).length > 0) {
                    diff[key] = nestedDiff;
                }
            }
            continue;
        }

        // Handle primitive values
        if (newValue === undefined) {
            if (oldValue !== undefined) {
                diff[key] = { oldValue: oldValue, newValue: '[%deleted%]' };
            }
        } else if (oldValue !== newValue) {
            diff[key] = {
                oldValue: oldValue === undefined ? '[%undefined%]' : oldValue,
                newValue: newValue === undefined ? '[%undefined%]' : newValue
            };
        }
    }

    const diffWithoutUndefined = removeUndefined(diff);
    return diffWithoutUndefined;
}


