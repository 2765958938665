// Add this at the top of firebaseCRUD.ts
export interface RetryOptions {
    maxAttempts: number;
    delayMs: number;
    backoffFactor: number;
    shouldRetry?: (error: unknown) => boolean;
}

export const defaultRetryOptions: RetryOptions = {
    maxAttempts: 3,
    delayMs: 1000,
    backoffFactor: 2,
    shouldRetry: (error: unknown) => {
        // Add specific Firebase error codes that should trigger a retry
        // For example, network errors, rate limiting, etc.
        const firebaseError = error as { code?: string };
        const retryableCodes = [
            'failed-precondition',
            'unavailable',
            'resource-exhausted',
            'deadline-exceeded',
            'cancelled',
            'network-request-failed'
        ];
        return retryableCodes.includes(firebaseError.code ?? '');
    }
};

export const retryDelay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function withRetry<T>(
    operation: () => Promise<T>,
    options: Partial<RetryOptions> = {}
): Promise<T> {
    const finalOptions = { ...defaultRetryOptions, ...options };
    let lastError: unknown;
    let delayTime = finalOptions.delayMs;

    for (let attempt = 1; attempt <= finalOptions.maxAttempts; attempt++) {
        try {
            return await operation();
        } catch (error) {
            lastError = error;

            if (attempt === finalOptions.maxAttempts ||
                (finalOptions.shouldRetry && !finalOptions.shouldRetry(error))) {
                break;
            }

            console.warn(
                `Operation failed on attempt ${attempt}/${finalOptions.maxAttempts}. ` +
                `Retrying in ${delayTime}ms...`,
                error
            );

            await retryDelay(delayTime);
            delayTime *= finalOptions.backoffFactor;
        }
    }

    throw lastError;
}