/**
 * Interface for the date components
 */
interface DateComponents {
    date: string;
    time: string;
}

/**
 * Interface for the formatted date strings
 */
interface FormattedDateStrings {
    UTC: DateComponents;
    localDate: DateComponents;
    timezone: string;
}

export class DateService {
    /**
     * Creates a date string in ISO format with local timezone information
     * Format example: "2024-03-21T15:30:00.000+03:00"
     */
    static now(): string {
        const date = new Date();
        const localISOString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .replace('Z', '');
        return `${localISOString}${this.getTimezoneOffset()}`;
    }

    static getLocalDateFromDate(date?: Date): string | undefined {
        if (!date) {
            return undefined;
        }
        const localISOString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .replace('Z', '');
        return `${localISOString}${this.getTimezoneOffset()}`;
    }

    static getLocalDateFromDateInputTarget(date: string) {
        // data format is YYYY-MM-DD
        const result = `${date}T23:59:59.999${this.getTimezoneOffset()}`;
        return result;
    }

    static getLocalDateInMsFromNow(inMs: number): string {
        const now = new Date();
        const result = new Date(now.getTime() + inMs - (now.getTimezoneOffset() * 60000))
            .toISOString()
            .replace('Z', '');
        return `${result}${this.getTimezoneOffset()}`;
    }

    /**
     * Gets the time until the given date as a formatted string
     * @param date - ISO date string with timezone information
     * @returns formatted string representing time until date
     */
    static getTimeUntilDate(date: string): {
        ms: number;
        text: string;
    } {
        const startMs = this.getUTCMilliseconds(date);
        const nowMs = new Date().getTime();
        const secondsRemaining = Math.floor((startMs - nowMs) / 1000);

        if (secondsRemaining <= 0) {
            return {
                ms: 0,
                text: "0 minutes"
            };
        }

        const days = Math.floor(secondsRemaining / 86400); // 86400 seconds in a day
        const hours = Math.floor((secondsRemaining % 86400) / 3600); // 3600 seconds in an hour
        const minutes = Math.floor((secondsRemaining % 3600) / 60);

        // More than 1 day: return days + hours
        if (days > 0) {
            return {
                ms: secondsRemaining * 1000,
                text: `${days} day${days > 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''}`
            };
        }

        // Less than 1 day but more than 4 hours: return hours only
        if (hours >= 4) {
            return {
                ms: secondsRemaining * 1000,
                text: `${hours} hour${hours !== 1 ? 's' : ''}`
            };
        }

        // More than 60 minutes but less than 4 hours: return hours + minutes
        if (hours > 0) {
            return {
                ms: secondsRemaining * 1000,
                text: `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`
            };
        }

        // Less than 60 minutes: return minutes only
        return {
            ms: secondsRemaining * 1000,
            text: `${minutes} minute${minutes !== 1 ? 's' : ''}`
        };
    }

    /**
     * Converts ISO string with timezone to UTC milliseconds
     * @param dateString - ISO date string with timezone information
     * @returns number of milliseconds since Unix epoch in UTC
     */
    private static getUTCMilliseconds(dateString: string): number {
        return new Date(dateString).getTime();
    }

    /**
     * Gets the number of seconds that have passed since the given date
     * @param sinceLocalDateString - ISO date string with timezone information
     * @returns number of seconds that have passed
     */
    static getSecondsSince(sinceLocalDateString: string): number {
        console.log('sinceLocalDateString', sinceLocalDateString);
        const startMs = this.getUTCMilliseconds(sinceLocalDateString);
        const nowMs = new Date().getTime();
        console.log('startMs', startMs);
        console.log('nowMs', nowMs);

        return Math.floor((nowMs - startMs) / 1000);
    }

    /**
     * Formats a date string for display in local timezone
     * @param dateString - ISO date string with timezone information
     */
    static formatLocalDate(dateString: string | undefined | null): string {
        if (!dateString) {
            return "";
        }
        const date = new Date(dateString);
        return date.toLocaleString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    }

    /**
     * Gets the timezone offset in the format "+HH:mm" or "-HH:mm"
     */
    private static getTimezoneOffset(): string {
        const date = new Date();
        const offset = -date.getTimezoneOffset();
        const hours = Math.floor(Math.abs(offset) / 60);
        const minutes = Math.abs(offset) % 60;
        const sign = offset >= 0 ? '+' : '-';

        return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    /**
     * Converts timezone offset to GMT format
     * @param offset - timezone offset in format "+HH:mm" or "-HH:mm"
     * @returns timezone in GMT format (e.g., "GMT+03:00")
     */
    private static formatGMTOffset(offset: string): string {
        return `GMT${offset}`;
    }

    /**
     * Parses a date string with timezone information to a Date object
     * @param dateString - ISO date string with timezone information
     */
    static parseDate(dateString: string): Date {
        return new Date(dateString);
    }

    /**
     * Formats a date string into components: UTC date/time, local date/time, and timezone
     * @param dateString - ISO date string with timezone information
     * @returns object containing UTC and local date/time components and timezone
     */
    static formatDateToStrings(dateString?: string): FormattedDateStrings | null {
        if (!dateString) {
            return null;
        }

        const date = this.parseDate(dateString);
        const timezone = this.getTimezoneFromDate(dateString);

        // Get UTC components
        const utcDate = date.toISOString().split('T')[0];
        const utcTime = date.toISOString().split('T')[1].split('.')[0].slice(0, 5); // Only take HH:mm

        // Get local components using the timezone from the string
        const localDate = date.toLocaleDateString();
        const localTime = date.toLocaleTimeString(undefined, {
            hour: 'numeric',
            minute: '2-digit'
        });

        const result: FormattedDateStrings = {
            UTC: {
                date: utcDate,
                time: utcTime
            },
            localDate: {
                date: localDate,
                time: localTime
            },
            timezone: timezone
        };
        return result;
    }

    /**
     * Extracts timezone information from a date string
     * @param dateString - ISO date string with timezone information
     * @returns timezone string in GMT format (e.g., "GMT+03:00")
     */
    static getTimezoneFromDate(dateString: string): string {
        const timezoneMatch = dateString.match(/([+-]\d{2}:\d{2})$/);
        const offset = timezoneMatch ? timezoneMatch[1] : this.getTimezoneOffset();
        return this.formatGMTOffset(offset);
    }

    /**
     * Formats a date according to the specified format
     * @param date - Date to format
     * @param format - Format string (e.g., "YYYY-MM-DD")
     * @returns Formatted date string
     */
    static formatDate(date: Date, format: string): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Replace format tokens with actual values
        return format
            .replace('YYYY', year.toString())
            .replace('MM', month)
            .replace('DD', day)
            .replace('HH', hours)
            .replace('mm', minutes)
            .replace('ss', seconds);
    }
} 